import { Theme } from "@chakra-ui/theme";
import { FormControlComponent } from "donation-form/src/components/form/useFormControl";
import { DonationFormConfig } from "donation-form/src/utils/api-cms";
import { FormContextProvider } from "donation-form/src/components/FormContextProvider";
import { useThemeStylesConfigured } from "donation-form/src/styles/useThemeStylesConfigured";

export function DonationFormPageComponent(props: {
  formConfig: DonationFormConfig;
  theme?: Theme | any;
}) {
  const styles = useThemeStylesConfigured({
    formConfig: props.formConfig,
    theme: props.theme,
  });
  return (
    <FormContextProvider formConfig={styles.configUpdated} styles={styles}>
      <FormControlComponent />
    </FormContextProvider>
  );
}
